import React from "react"
import ReadMoreBtn from "./read-more-btn";
import SectionHeading from "./section-heading";

class DiscoverMore extends React.Component {

    render() {
        return (
            <>
                <div className="row text-center py-5 mt-4">
                    <div className="col-12 col-md-6 text-center text-md-right">
                        <SectionHeading  color={'color-dark'} text={"Discover More"} />
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-left pt-2 pb-4">
                        <ReadMoreBtn type={'link'} url={'/blogs/'} text={'BROWSE ALL'}/>
                    </div>
                </div>
            </>
        )
    }
}

export default DiscoverMore
