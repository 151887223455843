import React, {Component} from "react"
import {Link} from "gatsby"
import CategoriesList from "./categories-list";
import RegulatoryAssociatedFields from "./regulatory-associated-fields";
import images from "./images";
import * as constants from "constants";

function fixLength(str, l){
    if(str === '' || str === undefined || str === null) return '';
    let a = str.substring(0,l).split(' ');
    a.pop();

    return str.length < l ? str : a.join(' ')+'...'
}



const RegulatoryUpdateCard = data => {

    return (
        <div className={"testimonial_box noselectable "+data.addToclass}>
            <div className="container-fluid px-0 h-100 bg-white">
                <div className="row h-100">
                    <div className="col-lg-4 px-3 pr-lg-0">
                        <div className="h-100 position-relative" style={{minHeight:'250px',backgroundColor:data.color,backgroundImage:'url('+data.img+')', backgroundPosition:'top right', backgroundRepeat:'no-repeat',backgroundSize:'contain'}}>

                            <div className="position-absolute h-100 w-100" style={{backgroundImage:'url('+images.backDotsIconDark+')', backgroundPosition:'15px 15px', backgroundRepeat:'no-repeat',backgroundSize:'120px auto', filter:'brightness(0.5)'}} > </div>

                            <div className="card bg-transparent text-white h-100 border-0 p-2">
                                    <div className="pt-4 px-4 pb-2" style={{position: 'absolute',bottom: '10px'}}>
                                        <CategoriesList data={{c1:data.data.node.categories != null ? data.data.node.categories.map(e => e.name) : [], c2:data.data.node.compliance_services, c3:data.data.node.licenses_and_approvals}}/>
                                    </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-8 px-3 pl-lg-0">
                        <div className="card border-0 h-100 p-2">
                            <div className="card-body">
                                <Link to={new URL(data.data.node.link).pathname} style={{textDecoration:'none'}} ><h5 className="card-title fsm-theme-title-heading color-normal" dangerouslySetInnerHTML={{__html: fixLength(data.data.node.title, 100)}} /></Link>
                                <br/>

                                <RegulatoryAssociatedFields data={data.data.node.acf} keyColor={"color-normal"} valueColor={"theme-color-blue"} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegulatoryUpdateCard
