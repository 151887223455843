import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import BlogCard from "./blog-card";
import images from "./images";


export default function HandpickedBlogCardsList(allBlogs) {

    let q = useStaticQuery(graphql`
  query {
 allWordpressWpComplianceServices {
                edges {
                  node {
                    name
                    wordpress_id
                  }
                }
           }
           allWordpressWpTop10Categories {
    edges {
      node {
        name
        wordpress_id
      }
    }
  }
  }`)

    //let compliance = q.allWordpressWpComplianceServices.edges.map(e => e.node.wordpress_id);
    let t10 = q.allWordpressWpTop10Categories.edges.map(e => e.node.name);

    let tb = allBlogs.data.filter(e => {
        if((e.node.categories !== undefined && e.node.categories !== null && e.node.categories.map(e2 => e2.name).some( ai => t10.includes(ai)))  && (e.node.compliance_services !== undefined && e.node.compliance_services !== null && e.node.compliance_services.length > 0)){
            return true
        }
    });

    let top3 = [];

    if(tb.length > 3){
        for (let i2 = 1; i2 <= 3; i2++) {
            let indv = '';
            let ind = 0;
            for (let i = 0; i < tb.length; i++) {
                if (indv !== '') {
                    if (new Date(tb[i].node.date) > new Date(indv)) {
                        indv = tb[i].node.date;
                        ind = i
                    }
                } else {
                    indv = tb[i].node.date
                }
            }
            top3.push(tb[ind]);
            tb.splice(ind, 1)
        }
    }else{
        top3 = tb;
    }

    let color = ['rgb(3, 67, 137)','rgb(222, 141, 10)','rgb(20, 175, 188)'];
    let bgcolor = ['linear-gradient(232deg, rgba(214,219,255,1) 0%, rgba(214,219,255,1) 0%, rgba(255,255,255,1) 100%)','linear-gradient(129deg, rgba(243,255,209,1) 0%, rgba(243,255,209,1) 0%, rgba(255,255,255,1) 100%)', 'linear-gradient(129deg, rgba(214,255,227,1) 0%, rgba(214,255,227,1) 0%, rgba(255,255,255,1) 100%)'];

    let img = [images.backHalfTriangleIconDark, images.artboard_8];


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="card-deck">
                        {top3.map((node, i) => (
                            <BlogCard data={node} img={img[i % img.length]} bgcolor={bgcolor[i % bgcolor.length]} color={color[i % color.length]} key={i}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
