import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"

const CategoriesList = data => {

    let c1 = data.data.c1;
    let c2 = data.data.c2;
    let c3 = data.data.c3;

    let q = useStaticQuery(graphql`
          query {
           allWordpressWpComplianceServices {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressWpLicensesAndApprovals {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressCategory {
                edges {
                  node {
                    name
                    link
                    parent_element {
                      name
                    }
                  }
                }
           }
          allWordpressWpTop10Categories {
                edges {
                  node {
                    name
                  }
                }
          }
          }`);

    c1 = c1.map(e => {
        return q.allWordpressCategory.edges.filter(e2 => e2.node.name === e)[0].node
    });
    c2 = c2.map(e => {
        return q.allWordpressWpComplianceServices.edges.filter(e2 => e2.node.wordpress_id === e)[0].node
    });
    c3 = c3.map(e => {
        return q.allWordpressWpLicensesAndApprovals.edges.filter(e2 => e2.node.wordpress_id === e)[0].node
    });

    let t10 = q.allWordpressWpTop10Categories.edges.map(e => e.node.name);

    let cat = [c1, c2, c3];

    cat.forEach((c, i) => {
        if (c.length >= 2) {
            c.forEach(c2 => {
                q.allWordpressCategory.edges.forEach(cd => {
                    if (cd.node.name === c2.name && cd.node.parent_element !== null) {
                        let index = cat[i].indexOf(cd.node.parent_element.name);
                        if (index !== -1) cat[i].splice(index, 1);
                    }
                })
            });

            if (cat[i].length >= 2) {
                let cf = c.filter(e => t10.includes(e.name));
                cat[i] = cf.length === 0 ? [c.sort()[0]] : cf.length >= 2 ? [cf.sort()[0]] : cf
            }
        }
    });

    cat = cat.filter(cp => cp.length >= 1).map(cp => cp[0])

    let color = data.color || 'color-white';
    let size = data.size || 'fsm-theme-category-heading';
    return (
        <div>
            <div className={"mb-2"}>
                <Link to={new URL(cat[0].link).pathname} className="text-decoration-none pb-3"><h5 className={size+" mb-0 "+ color}>{cat[0].name}</h5></Link>
            </div>
            {cat.filter((e, i) => i > 0).map((node, i, arr) => (
                <>
                    <Link to={new URL(node.link).pathname}  key={i} className={data.color !== undefined ? "fsm-theme-very-small-text" :"color-white-alpha fsm-theme-very-small-text"} style={{textDecoration:'underline'}}>{node.name}</Link>
                    <span className='fsm-theme-small-text'>&nbsp;&nbsp;{arr.length-1 !== i?"|":''}&nbsp;&nbsp;</span>
                </>
            ))}
        </div>

    )
}
export default CategoriesList
