/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react"

const FixedInfoButton = props => {
  const { buttonLabel, className } = props

  const [modal, setModal] = useState(false)

  const toggle = () => 
  {
    console.log("Model"+modal)
    setModal(!modal)
  }

  const handleClick = event => {
    toggle()
  }

  const className1=className+" model-lg"

  return (
    <div>
      <div className="home_fixed_info_button" onClick={toggle}>
        <div className="theme-bg-blue align-middle fixed_button rounded" style={{cursor:"pointer"}}>
          <span className="fixedButtonTexti text-white p-4" data-toggle="modal" data-target="#formModal">Enquire now</span>
        </div>
      </div>
    </div>     
  )
}

export default FixedInfoButton
