import React from "react"
import NewsCard from "./news-card";

const NewsCardSection = data => {


    let top3 = [];

    for (let i2 = 1; i2 <= 3; i2++) {
        let indv = '';
        let ind = 0;
        for (let i = 0; i < data.data.length; i++) {
            if (indv !== '') {
                if (new Date(data.data[i].node.date) > new Date(indv)) {
                    indv = data.data[i].node.date;
                    ind = i
                }
            } else {
                indv = data.data[i].node.date
            }
        }
        top3.push(data.data[ind]);
        data.data.splice(ind, 1)
    }


    let color = ['linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(214,219,255,1) 60%)', 'linear-gradient(336deg, rgba(255,255,255,1) 0%, rgba(243,255,209,1) 60%)', 'linear-gradient(336deg, rgba(255,255,255,1) 0%, rgba(214,255,227,1) 60%)'];

    return (
        <>

            <div className="row py-5">
                <div className="col-12">
                    <div className="card-deck">

                        {top3.map((node, i) => (
                            <div className={"card border-0 col-12 col-md-6 fsm-one-side-shadow rounded-0 mb-5 card-hover-effect "} style={{background:color[i]}} key={i}>
                                <NewsCard data={node}/>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>
    )
}
export default NewsCardSection
