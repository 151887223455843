import React from "react";
import { graphql } from "gatsby";
import Header from "../components/header";
import NewsCardSection from "../components/news-card-section";
import Menu from "../components/Menu";
import StaticBlogBanner from "../components/static-blog-banner";
import Footer from "../components/footer";
import FeaturedRegulatoryUpdateCard from "../components/featured-regulatory-update-card";
import RegulatoryUpdateSection from "../components/regulatory-update-section";
import PopupModel from "../components/popup-model";
import StaticBanner from "../components/static-banner";
import Lac from "../components/lac";
import DiscoverMore from "../components/discover-more";
import HandpickedSection from "../components/handpicked-section";
import SharePopupModel from "../components/share-popup-model";
import FormResponseModel from "../components/form-response-model";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import images from "../components/images";
import globe from "../globals";
import FixedInfoButton from "../components/FixedInfoButton";

export default ({ data }) => {
  let yoast_head = globe.domainReplace(
    data.allWordpressPage.edges[0].node.yoast_head
  );
  let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || "";
  let schema =
    temp !== undefined && temp !== null && temp.length > 70
      ? temp.substring(62, temp.length - 9)
      : {};

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
          {data.allWordpressPage.edges[0].node.title} | Food Safety Mantra
        </title>
        <meta
          property="og:image"
          content={data.site.siteMetadata.siteUrl + images.homeBanner}
        />
        {ReactHtmlParser(yoast_head)}

        <script type="application/ld+json">{schema}</script>
      </Helmet>

      <Header />

      <Menu />

      <StaticBlogBanner
        data={[
          data.allWordpressPost.edges,
          data.allWordpressWpRegulatoryUpdates.edges,
          data.allWordpressWpRegulatoryInsight.edges,
        ]}
      />

      <RegulatoryUpdateSection
        data={data.allWordpressWpRegulatoryUpdates.edges}
      />

      <StaticBanner />

      <HandpickedSection
        data={data.allWordpressPost.edges.concat(
          data.allWordpressWpRegulatoryUpdates.edges,
          data.allWordpressWpRegulatoryInsight.edges
        )}
      />

      <div className={"container mt-5"}>
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="container-fluid px-0 px-md-3">
              <Lac />

              <DiscoverMore />

              <FeaturedRegulatoryUpdateCard
                data={data.allWordpressWpRegulatoryUpdates.edges}
              />

              <NewsCardSection
                data={data.allWordpressPost.edges.concat(
                  data.allWordpressWpRegulatoryInsight.edges
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <FixedInfoButton/>

      <PopupModel name={"formModal"} />
      <SharePopupModel name={"shareModal"} />
      <FormResponseModel />
    </>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allWordpressPage(filter: { title: { eq: "Home" } }) {
      edges {
        node {
          title
          yoast_head
        }
      }
    }
    allWordpressPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          excerpt
          slug
          link
          date(formatString: "DD MMM, YYYY")
          categories {
            name
          }
          tags {
            name
          }
          yoast_head
        }
      }
    }
    allWordpressWpRegulatoryUpdates(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          excerpt
          slug
          link
          date(formatString: "DD MMM, YYYY")
          categories {
            name
          }
          tags {
            name
          }
          acf {
            date_of_implementation
            impacted_areas_business
            draft_or_gazette
            impacted_people
            reference_regulation
            reference
            summary
          }
          author {
            name
          }
          compliance_services
          licenses_and_approvals
          yoast_head
        }
      }
    }
    allWordpressWpRegulatoryInsight(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          excerpt
          slug
          link
          date(formatString: "DD MMM, YYYY")
          yoast_head
        }
      }
    }
  }
`;
