import React from "react"
import images from "./images";
import ReadMoreBtn from "./read-more-btn";
import SectionHeading from "./section-heading";

class Lac extends React.Component {

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-7">
                        <img src={images.lacCircle} className="img-fluid"/>
                    </div>
                    <div className="col-md-5">
                        <div className="p-4">
                            <SectionHeading  color={'color-dark'} text={"FOOD REGULATORY SERVICES"} />
                            <p className="fsm-theme-text mt-2 mb-3 color-normal">
                                Our regulatory solutions for Food Business Operators and the Indian importers for understanding and overcoming the regulatory obstacles
                            </p>

                            <ReadMoreBtn type={'btn'} text={'Book a consultation'}/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Lac
